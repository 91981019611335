<template>
  <dashboard-layout>
    <template #content>
      <!-- page header component -->
      <div class="level dashboard-navbar">
        <div class="level-left">
          <div class="level-item">
            <div class="content">
              <h1 class="title has-text-primary">
                <b-icon icon="exclamation-triangle" />
                Internal Server Error
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section class="section">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-three-fifths">
              <b-image
                :src="InternalServerErrorImage"
                alt="Internal Server Error"
                ratio="6by4"
                :rounded="false"
              />

              <div class="level">
                <div class="level-item">
                  <div class="content has-text-centered">
                    <b-button
                      type="is-primary"
                      tag="router-link"
                      to="/organizations"
                      icon-left="chevron-left"
                    >
                      go back home
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { DashboardLayout } from '@/layouts';
import InternalServerErrorImage from '@/assets/images/internal-server-error.svg';

export default {

  name: 'InternalServerError',

  components: {
    DashboardLayout,
  },

  data: () => ({
    InternalServerErrorImage,
  }),

};
</script>

<style>
</style>
